import React from 'react';

import SectionComponent from '../components/sections/sections';

const ContactPage: React.FC = (): JSX.Element => {
  return (
    <SectionComponent filter='Kontakt' />
  );
};

export default ContactPage;
